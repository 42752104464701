import React from 'react'
import {Container, Row, Col, Form} from 'react-bootstrap'
// import {NumericFormat} from 'react-number-format'

// NumericFormat docs https://s-yadav.github.io/react-number-format/docs

export default function Step5(props) {
  let {dispatch, state} = props
  let {email} = state

  return (
    <Container>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <Form>
            <Form.Group>
              <h2>Почти готово!</h2>
              <Form.Label>Укажите Ваш E-mail, и мы вышлем Вам рассчитанную стоимость и подарок
                <Form.Control
                  onKeyDown={e => {
                    if (e.keyCode == 13) {
                      navigate(1)
                    }
                  }}
                  onChange={e => dispatch({type: 'email', value: e.target.value})}
                  value={email}
                >
                </Form.Control>
              </Form.Label>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
