import './styles.scss'

import 'jquery'
import '@geedmo/yamm'
import 'jquery-mousewheel'
import 'node-waves/dist/waves.js'
import 'bootstrap'
import {Toast} from 'bootstrap'
import './components/gsap'
import './components/owl'
import './components/form'
import './components/modal/index.jsx'
import './components/navbar'
import './components/fancybox'
import './components/smooth_scroll'
import './components/configurator/index.jsx'
import './components/managers'
import './components/ymaps'
import './components/search'
import './components/cookies_warning'
import './components/apps/index.jsx'
import './components/btn_up'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

require('owl.carousel')
require('jquery.equalheights/jquery.equalheights')


async function elementUpdate(selector) {
  try {
    var html = await (await fetch(location.href)).text();
    var newdoc = new DOMParser().parseFromString(html, 'text/html');
    document.querySelector(selector).outerHTML = newdoc.querySelector(selector).outerHTML;
    // console.log('Элемент '+selector+' был успешно обновлен');
    return true;
  } catch (err) {
    // console.log('При обновлении элемента '+selector+' произошла ошибка:');
    // console.dir(err);
    return false;
  }
}

window.info_toast = new Toast($('#info_toast')[0], {autohide: true, delay: 5000})

window.showinfo = function (header='В разработке', body='', type = 'info') {
  $('#info_toast_header').html(header)
  $('#info_toast_body').html(body)

  $('#info_toast_header').attr('style', '');
  if (type == 'error') {
    $('#info_toast_header').attr('style', 'color:#ff8802!important');
  } else {
    $('#info_toast_header').attr('style', '');
  }
  info_toast.show()
}

window.$ = $

$(document).ready(() => {

  Waves.attach('.wbtn', ['waves-button', 'waves-float, waves-light']);
  Waves.init();

  Array.from(document.querySelectorAll('.in_develop_action'))
    .forEach(toastNode => {
      let inDevelop = new Toast(toastNode, {autohide: true, delay: 5000})
      toastNode.addEventListener('click', () => inDevelop.show())
    })

  document.getElementById('lead_form').addEventListener('keydown', function (e) {
    if (e.keyCode == 13) {
      $(this).submit();
    }
  })

  let owl=$(".owl-carousel")
  if(owl.length) owlCarousel({
    items: 5,
    margin: 16,
    loop: false,
    dots: true,
    lazyLoad: true,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      900: {
        items: 3,
      },
      1200: {
        items: 4,
      },
      1500: {
        items: 5,
      },
    }
  });

  // $('.eh').equalHeights();
  // $('body').fadeIn();
})


