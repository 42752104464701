export default function trigger(eventType, element) {
    let event
    if (document.createEvent) {
        event = document.createEvent("HTMLEvents");
        event.initEvent(eventType, true, true);
    } else {
        event = document.createEventObject();
        event.eventType = eventType;
    }

    event.eventName = eventType;

    if (document.createEvent) {
        element.dispatchEvent(event);
    } else {
        element.fireEvent("on" + event.eventType, event);
    }
}
