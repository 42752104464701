import * as React from 'react';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import Quiz from '../quiz/index.jsx';

$(document).ready(() => {

  const quiz = document.getElementById('quiz');
  if (quiz)
    createRoot(quiz).render(
      <StrictMode>
        <Quiz/>
      </StrictMode>
    )

})