import React from 'react'
import {Row, Col} from 'react-bootstrap'


export default function ItemsGallery(props) {
  const {dispatch, state, step_name} = props
  const {email, items, chapter, series} = state
  if (items.length) {
    let src = []
    switch (step_name) {
      case 'chapter':
        src = items
        break

      case 'series':
        src = items[chapter].childs
        break

      case 'system':
        src = items[chapter].childs[series].childs
        break
    }

    let list = src.map((item, index) => (
      <Col md={6} lg={4} xl={3} className="align-center fs_item ig_container" key={index}>
        <h3 className="series_name">{item.title}</h3>
        <div className="no_decor display-block">
          <div className="facades_chapter">
            <div className="facades_image">
              <img
                style={{height: '170px'}}
                className={state[step_name] == index ?
                  "img-thumbnail img-fluid quiz_img selected"
                  : "img-thumbnail img-fluid quiz_img"
                }
                src={item.image}
                onClick={() => dispatch({type: step_name, value: index})}
                alt=''/>
            </div>
          </div>
        </div>
      </Col>)
    )

    return (
      <Row className='justify-content-center fs_chapter'>
        {list}
      </Row>
    )
  } else return null

}
