import React from 'react'
import Dropzone from 'react-dropzone'
import '../../../misc/unique'
import SelectedFiles from './filelist.jsx'

export default class Feedback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fullname: '',
      email: '',
      message: '',
      files: [],
      order: '',

      email_error: false,
      message_error: false,
    }
  }

  handleDrop(acceptedFiles) {
    let files = this.state.files
    this.setState({
      files: files.concat(acceptedFiles).unique().sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          else return 0
        }
      )
    })
  }

  draw({getRootProps, getInputProps}) {
    return (<section>
      <div {...getRootProps()}>
        <input id="xxx" {...getInputProps()} />
        <div className='drug_drop_zone'>
          <img src='assets/images/d&d.png' alt=""/>
          Переместите сюда файлы или кликните мышкой
        </div>
      </div>
    </section>)
  }

  sendData() {
    let data = new FormData()
    let st = this.state
    let self = this
    data.append('action', 'feedback')
    data.append('fullname', st.fullname)
    data.append('order', st.order)
    data.append('email', st.email)
    data.append('message', st.message)

    st.files.forEach((file, i) => {
      data.append('files[]', file, file.name)
    })
    // showAjax();
    fetch(document.location.origin + '/assets/components/modxsite/connectors/connector.php?pub_action=feedback', {
      method: 'POST', body: data, mode: "same-origin",
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          showinfo('Сообщение отправлено','Благодарим за Ваш отзыв');
          document.querySelector('#feedback .btn-close').click()

        } else {
          //выводим ошибки
          let e = {
            email_error: false,
            message_error: false
          }
          result.data.forEach(item => {
            switch (item.id) {
              case 'email':
                e.email_error = true
                break
              case 'message':
                e.message_error = true
                break
            }
            self.setState(e)
          })
          showinfo('Ошибки в форме', 'Пожалуйста, заполните корректно все обязательные поля', 'error');
        }
      })
      .catch((error) => {
        window.console.error('ERROR: ', error)
        // throw error
      })
  }


  render() {
    let state = this.state
    return (<form
      className="dropzone"
      data-action="feedback"
      action="/"
    >
      <div className="modal-body">
        <p>Оставьте Ваш отзыв.</p>
        <input type="hidden" name="page_id" value="{$modx->resource->id}"/>
        <input type="hidden" name="pagetitle" value="{$modx->resource->pagetitle}"/>
        <input type="hidden" name="alias" value="feedback"/>
        <input type="hidden" name="title" value="Оставить отзыв"/>

        <label>Как к Вам обращаться<span className="required"> *</span>
          <input type="text"
                 name="fullname"
                 onChange={(event) => this.setState({fullname: event.target.value})}
                 value={state.fullname}
          />
        </label>

        <label>Номер заказа
          <input type="text"
                 name="order"
                 onChange={(event) => this.setState({order: event.target.value})}
                 value={state.order}
          />
        </label>

        <label>E-mail<span className="required"> *</span>:
          <input
            type="text"
            name="email"
            value={state.email}
            className={state.email_error ? 'error' : ''}
            onChange={(event) => this.setState({email: event.target.value})}
          />
        </label>

        <label id="feedback_message">
          Ваше сообщение<span className="required"> *</span>
          <textarea
            name="message"
            rows="5"
            value={state.message}
            className={state.message_error ? 'error' : ''}
            onChange={(event) => this.setState({message: event.target.value})}
          >
                </textarea>
        </label>
        <label>
          Если есть фото, можете их загрузить
          <Dropzone
            id='feedback_dropzone'
            onDrop={this.handleDrop.bind(this)}
            minSize={0}
            maxSize={5242880}
            multiple
          >
            {this.draw.bind(this)}
          </Dropzone>
        </label>
        <SelectedFiles files={this.state.files} updateFiles={this.setState.bind(this)}/>

        <p className="law">Заполняя эту форму, Вы&nbsp;соглашаетесь получать рассылку информационных материалов
          от&nbsp;нашей компании. Со&nbsp;своей стороны, мы&nbsp;гарантируем, что информация об&nbsp;адресе
          Вашей электронной почты и&nbsp;номере телефона не&nbsp;будет передана третьим лицам в&nbsp;соответствии
          с&nbsp;нашей политикой конфиденциальности и&nbsp;ФЗ&nbsp;от&nbsp;27 июля 2006 года &#8470;&nbsp;152
          &laquo;О&nbsp;персональных данных&raquo;.</p>
      </div>

      <div className="modal-footer">
        <button type="button" className="wbtn" data-bs-dismiss="modal">Отмена</button>
        <button type="button" className="wbtn" onClick={this.sendData.bind(this)}>Отправить</button>
      </div>
    </form>)
  }
}