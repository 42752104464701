import React from 'react'
import {Container} from 'react-bootstrap'
import ItemsGallery from "../items-gallery.jsx";

export default function Step3(props) {
  let {navigate, dispatch, state} = props
  return (
    <Container>
      <h2>Выберите серию фасадных систем:</h2>
      <ItemsGallery navigate={navigate} step_name='series' dispatch={dispatch} state={state}/>
    </Container>
  )
}
