import React from 'react'
import {Container, Row, Col, Form} from 'react-bootstrap'

export default function Step6(props) {

  return (
    <Container>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <h2>Вы молодец!!!</h2>
        </Col>
      </Row>
    </Container>
  )
}
