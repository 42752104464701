// require('jquery.autocomplete/jquery.autocomplete')
//
$(document).ready(() => {

  $('#search_btn').click(() => {
    $('#search_input').fadeIn()
    // $('#search_input input').focus()
  })

  $(document).keydown(function (e) {
    // ESCAPE key pressed
    if (e.keyCode == 27)
      $('#search_input').fadeOut()
  });

  $(document).click(function (e) {
    e.stopPropagation();
    console.log(e)
    if (e.target.classList.contains('ya-site-form__input-text') ||
        e.target.classList.contains('ya-site-form__submit') ||
        e.target.classList.contains('icon-search'))
      return false;
    $('#search_input').fadeOut()
  })

//   // let a = $('#search_input input').autocomplete({
//   //   serviceUrl: location.protocol + '//' + location.host + '/assets/components/modxsite/connectors/connector.php?pub_action=search',
//   //   deferRequestBy: 300,
//   //   identifier: 'search',
//   //   onSelect: data => location.href = data
//   // })
//   //
//   // a.formatResult = function (value, data) {
//   //   console.log('value', value)
//   //   console.log('data', data)
//   //   var search = value
//   //   if (this.dataKey) data = data[this.dataKey]
//   //   var pattern = '(' + value.replace(this.regEx, '\\$1') + ')'
//   //   console.log('result', ((search + ' (' + data).replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>') + ')'))
//   //   return ((search + ' (' + data).replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>') + ')')
//   // }
//
//   // a.enable()
//
})
