function checkCookies(){
  let cookieDate = localStorage.getItem('cookieDate');
  let cookieNotification = document.getElementById('cookie_notification');
  let cookieBtn = cookieNotification.querySelector('.cookie_accept');

  // Если записи про кукисы нет или она просрочена на 1 год, то показываем информацию про кукисы
  if( !cookieDate || (+cookieDate + 31536000000) < Date.now() ){
    cookieNotification.classList.add('show');
  }

  // При клике на кнопку, в локальное хранилище записывается текущая дата в системе UNIX
  cookieBtn.addEventListener('click', function(){
    localStorage.setItem( 'cookieDate', Date.now() );
    cookieNotification.classList.remove('show');
  })
}
checkCookies();

// $(document).ready(() => {
//   $(".warning__apply").click(() => {
//     $('.cookie_warning').removeClass("warning__active")
//   })
// })
