import trigger from '../../misc/trigger'

$(document).ready(() => {

  Array.from(document.querySelectorAll('.modal'))
    .forEach(el => el.addEventListener('hide.bs.modal', ev => {
      let f = ev.target.getElementsByTagName('form')[0]
      if (f) {
        f.reset()
      }
    }))

  $('form[data-action] [type=submit]').on('click', function (ev) {
    let form = $(ev.target).closest('form');
    // showAjax('true');

    let action = form.data('action')

    if (action !== '') {
      if (!form.data('in_request')) {
        form.data('in_request', true);
// console.log('form',form)
        let m_data = new FormData(form[0]);
        // m_data.append('action', action);

        // showAjax();
        fetch(document.location.origin + '/assets/components/modxsite/connectors/connector.php?pub_action=' + action, {
          method: 'POST',
          body: m_data,
          mode: "same-origin",
        })
          .then(response => response.json())
          .then(result => {
//console.log(result)
            form.data('in_request', false);

            if (result.success) {

              //проверка состояния заказа
              if (action == 'order_check') {
                form.find('.modal-body').html('<div class="accent">Заказ ' + result.message + '</span>')
                setTimeout(() => {
                  form.find('.btn-close').trigger('click')
                }, 5000)
              } else {
                //можно передавать в form.data
                var txt = form.data('message')
                showinfo('Сообщение отправлено', txt ? txt : 'В ближайшее время наш менеджер свяжется с Вами');
                // form.trigger("reset");
                form.find('.error').removeClass('error');
                try { // если не модалка, игнорим
                  form.closest('.modal').find('.btn-close')[0].click()
                } catch
                  (e) {
                }
              }
              form[0].reset()

            } else {
              form.find('.error').removeClass('error');
              result.data.forEach(e => {
                form.find('input[name="' + e.id + '"]').addClass('error')
              })
              showinfo('Ошибки в форме', 'Пожалуйста, заполните корректно все обязательные поля', 'error');
            }
          })
          .catch((error) => {
            form.data('in_request', false);
            // form.trigger('reset');
            window.console.error('ERROR: ', error)
            // throw error
          })
      }
    }
    return false;
  })
});
