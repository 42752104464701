$(document).ready(function () {
    document.querySelectorAll('.dropdown-toggle').forEach((el) => {
        el.addEventListener('show.bs.dropdown', function () {
            $(this).parent().find('.dropdown-menu').first().stop(true, true).slideDown();
        })

        el.addEventListener('hide.bs.dropdown', function (ev) {
            $(this).parent().find('.dropdown-menu').first().stop(true, true).slideUp();
        })
    })

//     $('.dropdown-toggle').on('show.bs.dropdown', function() {
// console.log('show.bs.dropdown')
//         $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
//     });
//
// // Add slideUp animation to Bootstrap dropdown when collapsing.
//     $('.dropdown-toggle').on('hide.bs.dropdown', function() {
// console.log('hide.bs.dropdown')
//         $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
//     });
})
