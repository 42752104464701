export default function reducer(state, action) {
  const {chapter, series, log} = state
  const {value: val, type:atype} = action

  const isEqual=(i1, i2)=>(i1.action==i2.action && i1.val==i2.val)

  const add=(item)=>{
    if(!log.length || !isEqual(log[log.length-1], item))
      log.push(item)
  }

  switch (atype) {

    case 'base':
      return {...state, base: val}

    case 'step':
      // history.pushState(null, null, state.base + '/' + action.value)
      add({action:atype, val: val})
      return {...state, step: val, series: chapter == 4 && val == 3 ? 0 : series, log:log}

    case 'square':
      add({action:atype, val: val})
      return {...state, square: val, log:log}

    case 'chapter':
      add({action:atype, val: val})
      return {...state, chapter: val, series: -1, system: -1, log:log}

    case 'series':
      add({action:atype, val: val})
      return {...state, series: val, system: -1, log:log}

    case 'system':
      add({action:atype, val: val})
      return {...state, system: val, log:log}

    case 'email':
      add({action:atype, val: val})
      return {...state, email: val, log:log}

    case 'items':
      return {...state, items: val}

    // case 'done':
    //   return {...state, done: true}
    //
    default:
      throw new Error(`Unknown action type: ${atype}`)
  }
}

export var initialQuizState = {
  base: '',
  step: 0,
  square: 0,
  chapter: -1,
  series: -1,
  system: -1,
  email: '',
  done: false,
  items: [],
  log: [],
}