import React, {useLayoutEffect, useEffect, useReducer, useState} from 'react';
import gsap from 'gsap';
import AnimateHeight from 'react-animate-height';

import reducer, {initialQuizState} from './reducer.jsx'
import PathSteps from './path.jsx'
import UseUnload from './useunload.jsx'
import Step1 from './steps/step1.jsx'
import Step2 from './steps/step2.jsx'
import Step3 from './steps/step3.jsx'
import Step4 from './steps/step4.jsx'
import Step5 from './steps/step5.jsx'
import Step6 from './steps/step6.jsx'

function cleanItems(arr) {
  return arr.map(item => {

    if (item.title) item.title = item.title.replace(/<\/?[^>]+(>|$)/g, " ").replace(/&.+?;/g, " ")
    if (item.image) item.image = '/assets/images/' + item.image

    if (item.id == 12) {
      let x = structuredClone(item)
      x.id = 999999
      item.childs = [x]
    }

    if (item.childs) {
      item.childs = cleanItems(Object.values(item.childs))
    }
    return item
  })
}

function getItems(dispatch) {
  let items = []

  fetch('/assets/components/modxsite/connectors/connector.php?pub_action=get_catalog', {
    method: 'GET', headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
  }).then(response => response.json())
    .then(data => {
      if (!data.success) throw new Error('Fetch error on get tab items from server')

      //else
      if (Object.values(data.object).length) {
        items = cleanItems(Object.values(data.object), 0)
      }

      dispatch({type: 'items', value: items})
    })
}


export default function Quiz() {
  let myTween = gsap.timeline({paused: true})
  let myElement = null

  const [state, dispatch] = useReducer(reducer, initialQuizState);
  const [height, setHeight] = useState('auto')
  const [size, setSize] = useState([0, 0]);

  const {step, items, square, chapter, series, system, log, email} = state


  UseUnload(e => {
    e.preventDefault();

    let data = {
      quiz_id: 'price_calc',
      step: step,
      chapter: chapter != -1 ? items[chapter].id : 0,
      series: chapter != -1 && series != -1 ? items[chapter].childs[series].id : 0,
      system: chapter != -1 && series != -1 && system != -1 ? items[chapter].childs[series].childs[system].id : 0,
      square: square,
      email: email,
      log: log,
      phone: 'no phone'
    }
    fetch('/assets/components/modxsite/connectors/connector.php?pub_action=quiz_cancel', {
      method: 'POST', body: JSON.stringify(data), headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    }).then(response => response.json())
      .then(data => {
        if (!data.success)
          // throw new Error('Fetch error on get tab items from server')

          //else
          console.log('quiz cancel DATA', data)
      })
    e.returnValue = '';
  });


  useEffect(() => {
    getItems(dispatch)
  }, [])


  useEffect(() => {
    if (myElement) setHeight(myElement.offsetHeight + 24)
    Waves.attach('.wbtn', ['waves-button', 'waves-float, waves-light'])
  })

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    dispatch({type: 'base', value: location.href})
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const isValid = () => (step === 0 && state.square > 0 ||
    step === 1 && state.chapter >= 0 ||
    step === 2 && state.series >= 0 ||
    step === 3 && state.system >= 0 ||
    step === 4 && !!validateEmail(state.email)
)

  let no_series = [3, 4]

  const navigate = (dir) => {
    myTween
      .clear()
      .to(myElement, {opacity: 0, duration: 0.2})
      .call(() => {
        dispatch({type: 'step', value: step + (step == 1 && no_series.indexOf(chapter) != -1 ? 2 : 1) * dir})
      })
      .to(myElement, {opacity: 1, duration: 0.2})
      .play();
  }

  const sendResult = () => {
    let data = {
      quiz_id: 'price_calc',
      step: step,
      chapter: chapter != -1 ? items[chapter].id : 0,
      series: chapter != -1 && series != -1 ? items[chapter].childs[series].id : 0,
      system: chapter != -1 && series != -1 && system != -1 ? items[chapter].childs[series].childs[system].id : 0,
      square: square,
      email: email,
      log: log
    }

    fetch('/assets/components/modxsite/connectors/connector.php?pub_action=quiz_confirm', {
      method: 'POST', body: JSON.stringify(data), headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    }).then(response => response.json())
      .then(data => {
        if (!data.success)
          throw new Error('Fetch error on get tab items from server')
          //else
          navigate(1)
      })

  }

  return (<AnimateHeight
    duration={300}
    height={height}
    className={'quiz_rat'}
  >
    <div
      ref={(el) => (myElement = el)}
    >

      <PathSteps dispatch={dispatch} state={state}/>

      {step === 0 && <Step1 navigate={navigate} dispatch={dispatch} state={state}/>}
      {step === 1 && <Step2 navigate={navigate} dispatch={dispatch} state={state}/>}
      {step === 2 && <Step3 navigate={navigate} dispatch={dispatch} state={state}/>}
      {step === 3 && <Step4 navigate={navigate} dispatch={dispatch} state={state}/>}
      {step === 4 && <Step5 navigate={navigate} dispatch={dispatch} state={state}/>}
      {step === 5 && <Step6/>}

      <div className='quiz_nav'>
        {step > 0 && step<6 && <div className='wbtn' onClick={() => navigate(-1)}>
          Назад
        </div>}
        {step < 4 && isValid() && <div className='wbtn' onClick={() => navigate(1)}>
          Далее
        </div>}
        {step == 4 && isValid() && <div className='wbtn' onClick={() => sendResult()}>
          Узнать результат
        </div>}
      </div>

    </div>
  </AnimateHeight>)
}
