import {gsap, TweenMax, TimelineMax, Quad} from 'gsap'

gsap.config({
  // autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  // trialWarn: false,
  // units: {left: "%", top: "%", rotation: "rad"}
});

let elem = null
TweenMax.set(".flipper_wrapper", {perspective: 2000});
TweenMax.set(".flipper_card", {transformStyle: "preserve-3d"});
TweenMax.set(".flipper_back", {rotationY: -180});
TweenMax.set([".flipper_back", ".flipper_front"], {backfaceVisibility: "hidden"});

$(".flipper_wrapper").each((i, el) => {

  let tl = new TimelineMax({paused: true})
  elem = $(el).find('.flipper_card')
  tl.set(el, {willChange: "transform"})
    .to(elem, 1, {
      rotationY: 180,
      ease: Quad.easeIn
    })
  el.animation = tl;

  $(el).on("mouseenter", function () {
    this.animation.play();
  }).on("mouseleave", function () {
    this.animation.reverse();
  });

});
