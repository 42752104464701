import * as React from 'react';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import SendLetter from "./send_letter/index.jsx";
import Feedback from "./feedback/index.jsx";

document.querySelectorAll('.modal').forEach(comp => {
  comp.addEventListener('show.bs.modal', () => document.querySelectorAll('.error').forEach(el => el.classList.remove('error')))
})

let comp = document.getElementById('about_system')
if (comp) {
  comp.addEventListener('show.bs.modal', function (event) {
    let el = $(event.explicitOriginalTarget)
    let doc = $(event.target).find('#about_system_content')
    let name = $(event.target).find('#about_system_name')
    doc.html(
      '<img class="about_system_img" src="' + el.data('img') + '" alt="' + el.data('name') + '"> ' + el.data('content')
    );
    name.text(el.data('name'));
  })
}

document.querySelectorAll('.modal-decor').forEach(comp => {
  comp.addEventListener('show.bs.modal', function (event) {
    let btn = $(event.explicitOriginalTarget)
    $(btn.data('bs-target')).find('.decor-ttl2').html(btn.data('ttl'))
  })
})

comp = document.getElementById('request')
if (comp) {
  comp.addEventListener('show.bs.modal', function (event) {
// console.log('event',event)
    window.btn = event.relatedTarget
    document.getElementById('request_title').innerHTML = btn.dataset.ttl
    document.getElementById('request_text').innerHTML = btn.dataset.text
    document.getElementById('request_alias').value = btn.dataset.alias
    // document.getElementById('request_title').value = btn.dataset.ttl

    let l = document.querySelector('#request_phone span')
    if (['callback_request'].includes(btn.dataset.alias))
      l.style.display = 'inline'
    else
      l.style.display = 'none'

    l = document.querySelector('#request_message')
    if (['send_letter', 'ask_question', 'get_wholesale_commercial_proposal'].includes(btn.dataset.alias)) {
      l.innerHTML = btn.dataset.alias == 'get_wholesale_commercial_proposal'
        ?
        'Пожелания (если есть):<textarea name="message" rows="5"></textarea>'
        :
        'Ваше сообщение<span class="required"> *</span><textarea name="message" rows="5"></textarea>'
      l.style.display = 'block'
    } else
      l.style.display = 'none'

  })
}


comp = document.getElementById('callback')
if (comp) {
  comp.addEventListener('show.bs.modal', function (event) {
    window.btn = event.relatedTarget
    let el = document.getElementById('callback_title')
    el && (el.innerHTML = btn.dataset.ttl)
  })
}

comp = document.getElementById('send_letter');
if (comp) {
  comp.addEventListener('show.bs.modal', function (event) {
    window.slRoot = createRoot(document.getElementById('react_letter'))
    slRoot.render(
      <StrictMode>
        <SendLetter/>
      </StrictMode>
    )
  })

  comp.addEventListener('hidden.bs.modal', event => {
    slRoot.unmount()
  })

}

comp = document.getElementById('feedback');
if (comp) {
  comp.addEventListener('show.bs.modal', function (event) {
    window.fbRoot = createRoot(document.getElementById('react_feedback'))
    fbRoot.render(
      <StrictMode>
        <Feedback/>
      </StrictMode>
    )
  })

  comp.addEventListener('hidden.bs.modal', event => {
    fbRoot.unmount()
  })

}

comp = document.getElementById('order_check')
if (comp) {
  comp.addEventListener('show.bs.modal', (event) => {
    $(comp).find('.modal-body').html("<input class=\"align-center\" name=\"order\" type=\"text\" placeholder=\"Введите номер заказа, например, 47/8\">")
  })
}

