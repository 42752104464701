import React, {useState} from 'react';

const steps = [
  'Площадь',
  'Тип',
  'Серия',
  'Система',
  'Результат',
]


export default function PathSteps(props) {
  let {dispatch, state} = props
  let {step} = state

  let listItems = steps.map((item, index) => (
      <li
        // onClick={(index < step) ? () => dispatch({type: 'step', value: index}) : null}
        className={(index <= step) ? 'step_on' : ''}
        key={index}
      >
        {steps[index]}
      </li>
    )
  )

  return (
    <ul className={'path_steps'}>{listItems}</ul>
  )

}