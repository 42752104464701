import React from 'react'
import {Container} from 'react-bootstrap'
import ItemsGallery from '../items-gallery.jsx'

export default function Step2(props) {
  let {navigate, dispatch, state} = props
  return (
    <Container>
      <h2>Выберите тип фасадов:</h2>
      <ItemsGallery step_name='chapter' navigate={navigate} dispatch={dispatch} state={state}/>
    </Container>
  )
}
