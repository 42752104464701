import React from 'react'

export default class SelectedFiles extends React.Component {
  constructor(props) {
    super(props);

    this.updateFiles=this.props.updateFiles
    this.state = {
      files: this.props.files
    }
  }

  componentDidMount(nextProps, nextState, nextContext) {
    this.updateFiles({files: this.state.files})
  }

  delFile(ev, index) {
    ev.stopPropagation()
    let files = this.props.files
    files.splice(index, 1)
    this.updateFiles({files:files})
  }

  render() {
    let {files, updateFiles} = this.props
    if (files) {
      let lst = []
      let list = files.map((f, i) => {
        return (<li key={i}>
          {f.name}
          <span className='sf_del_btn' onClick={e => this.delFile(e, i)}>
              Х
            </span>
        </li>)
      })

      return (<ul className='sf_filelist'>
        {list}
      </ul>)
    } else return null
  }
}
