import rangesliderJs from 'rangeslider-js'
import bootstrap from 'bootstrap'

function slide(value, percent, position) {
  $(this).closest('.slider_block').find('.curr').html(value)
}

$(document).ready(function () {

//----------------------------------------------------------
  $('.mr_info').click(function () {
    return false;
  });

//----------------------------------------------------------
  $('.mr_info').mouseenter(function () {
    var d = mcfg[$(this).closest('.rating').data('id')].split(';'),
      s = '<div class="mr_detail"><table>',
      ca = 0,
      sa = 0;

    d.forEach(function (e, i) {
      let di = e.split('-');
      let names = ['Профессионализм', 'Оперативность', 'Вежливость']
      let val = di[1] / di[0] || 0
      if (val)
        val = accounting.formatNumber(val, 1, "", ",")
      else
        val = '-'

      s += '<tr><td>' + names[i] + '</td><td>' + val + '</td></tr>';
    });
    s += '</table></div>';
    $(this).append(s);
  });

//----------------------------------------------------------
  $('.mr_info').mouseleave(function () {
    $(this).find('.mr_detail').remove();
  });

//----------------------------------------------------------
  $('.manager_rate').click(function () {
    var f = $('#rate_modal'),
      m = $(this).data('mgr'),
      name = $(this).data('name'),
      d = mcfg[m]

    f.find('input[name="mgr"]').val(m)
    $('#mr_name').text(name)

    var s = '',
      fields = [];

    // d.forEach(function (e, i) {
    //   fields.unshift('migx_' + e.MIGX_id);
    //   s += '<div id="migx_' + e.MIGX_id + '" class="row"><div class="fgu4 title">' + e.name + '</div><div class="fgu8"><div class="rate_group">';
    //   var i = 1;
    //   while (i < 11) {
    //     s += '<div class="rg_item">' + i + '<input type="radio" class="styled" name="migx_' + e.MIGX_id + '" value="' + i + '"/></div>';
    //     i++;
    //   }
    //   s += '</div></div></div>';
    // });

    // f.find('input[name="fields"]').val(fields.join());
    // $('#rm_items').html(s);
    // Custom.init();
    return false;
  });


//----------------------------------------------------------
  $('.rm_submit').click(function () {
    var f = $(this).closest('form');
    $.ajax({
      url: 'assets/components/modxsite/connectors/connector.php?pub_action=rateit',
      type: "post",
      data: f.serialize(),
      dataType: 'text',
      success: function (data) {
        data = JSON.parse(data)

        var mgr = parseInt(f.find('[name="mgr"]').val());

        if (data.success) {
          //обновим рейтинг
          let s = mcfg[mgr] = data.message,
            cnt = 0,
            sum = 0,
            i = 0,
            r = s.split(';')
          r.forEach(e => {
            let a = e.split('-')
            cnt = parseInt(a[0])
            sum += parseInt(a[1])
          })
// console.log(cnt, sum)


          $('.rating[data-id="' + mgr + '"] .mr_info span').text(accounting.formatNumber((sum / 3) / cnt, 1, "", ","))
          $('.rating[data-id="' + mgr + '"] .mr_cnt span').text(cnt)
          $('.mr_rate[data-mgr="' + mgr + '"]').remove()
          showinfo('Оценка сохранена', 'Спасибо за Вашу оценку')
        }
      },

      error: request => console.log('ERROR: ', request),

      complete: () => $('#rate_modal .btn-close').click()

    });
    return false;
  });

  var e = document.getElementById('rm_rofessionalism');
  rangesliderJs.create(e, {onSlide: slide.bind(e)})

  e = document.getElementById('rm_efficiency');
  rangesliderJs.create(e, {onSlide: slide.bind(e)})

  e = document.getElementById('rm_polite');
  rangesliderJs.create(e, {onSlide: slide.bind(e)})
})
;

