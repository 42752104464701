import React from "react";
// import "./style.css";

export default class MainImage extends React.Component{
  render() {
    return (
      <div className='config_img'>
        <img src='assets/images/fs/pvh-emal/prestige/alexa/1.jpg'/>
      </div>
    )
  }
}
