import React from 'react'
import {Container, Row, Col, Form} from 'react-bootstrap'
import {NumericFormat} from 'react-number-format'

// NumericFormat docs https://s-yadav.github.io/react-number-format/docs

export default function Step1(props) {
  let {navigate, dispatch, state} = props
  let {square} = state

  return (
    <Container>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <Form>
            <Form.Group>
              <Form.Label>Напишите общую площадь мебельных фасадов, включая глухие и витринные
                (м<sup>2</sup>)
                <NumericFormat
                  value={square}
                  allowedDecimalSeparators={['%', ',']}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  decimalScale={3}
                  customInput={Form.Control}
                  onKeyDown={e => {
                    if (e.keyCode == 13) {
                      navigate(1)
                    }
                  }}
                  onChange={e => dispatch({type: 'square', value: e.target.value})}
                />
              </Form.Label>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
