import React from "react"
import MainImage from "./main_image.jsx"

export default function App() {
  return (
    <div>
      <MainImage/>
    </div>
  );
}
