import {Fancybox, Carousel} from "@fancyapps/ui/"

$(document).ready(function () {

  Fancybox.bind("[data-fancybox]", {
    protect: true,
  })

  Fancybox.bind(".fancybox_door", {
    protect: true,
    padding: 0,
    fitToView: false,
    scroll: true,
    closeClick: true,
    touch: {
      vertical: true,
      momentum: true
    },

    image: {
      preload: true,
    },

    hideScrollbar: false,
    helpers: {
      title: {
        type: 'none'
      },
    },

    on: {
      ready: function (obj) {
        window.o = obj

        let images = obj.items[0].images.split(',')
        let trigger = $(obj.options.$trigger)
        let id = obj.items[0].src
        let ttl = obj.items[0].ttl

        if (!ttl) {
          ttl = [
            'высота 2100мм,<br>ширина 450мм',
            'высота 2100мм,<br>ширина 525мм',
            'высота 2100мм,<br>ширина 600мм',
          ]
        } else
          ttl = ttl.split(',')

        let content = '<div class="doors_ttl">ВНЕШНИЙ ВИД ФРЕЗЕРОВОК ПРИ ЗАДАННЫХ РАЗМЕРАХ</div>' +
          '<div class="container"><div class="row doors_wrap">'

        for (let i = 0; i < images.length; i++) {
          content += '<div class="col-sm-12 col-md-4"><img class="img-fluid" src="' + images[i] + '"><div class="doors_info">' + ttl[i] + '</div></div>'
        }

        content += '</div></div>';
        $(id).html(content);
      }
    }
  })


// carousel
  let obj = document.querySelectorAll(".furniture_carousel")
obj=Array.from(obj)
//console.log('obj', obj);
  obj.forEach(el => {
    //console.log('furniture_carousel', el);
    new Carousel(el, {
      // Dots: true,
      // Navigation: true,
      infinite: false,
      slidesPerPage: 'auto',
    })
  })

  obj = document.querySelectorAll(".video_carousel")
  obj=Array.from(obj)
//console.log('obj', obj);
  obj.forEach(el => {
    //console.log('furniture_carousel', el);
    new Carousel(el, {
      // Dots: true,
      // Navigation: true,
      infinite: false,
      slidesPerPage: 'auto',
    })
  })

})